import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './BusinessGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import Ready from "../../components/Ready/Ready";
import { Helmet } from "react-helmet-async";

const BusinessGuide2 = () => {
	const menuContents = [
		{ title: "사업안내", url: "/BusinessGuide/intro" },
		{ title: "분양일정", url: "/BusinessGuide/plan" },
		{ title: "계약서류안내", url: "/BusinessGuide/documents" }
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			<Helmet>
				<title>계약서류안내 - 힐스테이트 도안리버파크2차</title>
				<meta name="description" content="힐스테이트 도안리버파크2차의 분양일정을 통해 중요한 분양 일정을 확인하세요. 
				분양 일정과 필요한 모든 정보를 제공하여 성공적인 분양을 지원합니다." />
				<meta name="keywords" content="힐스테이트도안리버파크,힐스테이트도안리버파크 모델하우스,힐스테이트도안리버파크 분양가,힐스테이트도안2차,도안힐스테이트2차,도안힐스테이트리버파크2차,힐스테이트도안리버파크2차,힐스테이트도안리버파크 5단지" />
				<link rel="canonical" href="https://www.hillstate-thefrist.com/BusinessGuide/documents" />
			</Helmet>

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="사업개요" />

			<MenuBar contents={menuContents} />

			<Ready />

			<Footer />
		</div>
	)
}

export default BusinessGuide2;
